import React, { useEffect, useState } from 'react'
import FuncFetch from '../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react';
import ReactSelect from 'react-select';
import { Spinner } from 'react-bootstrap';
import { BsCheck, BsX } from 'react-icons/bs';
import useFetch from '../Utilities/useFetch';
import ErrorBoundary from '../Auth/ErrorBoundary';

function CollectorDropdown({
    collector,
    readOnly,
    className,
    debtorID,
    updateNCDAndActivities
}) {
    const [collectorOptions, setCollectorOptions] = useState();
    const {instance,accounts} = useMsal();
    const [selectedCollector, setSelectedCollector] = useState();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const [error, setError] = useState();
    const { REACT_APP_API_URL } = process.env;
    const {data:collectors,loading:loadingCollectors} = useFetch(true,`${REACT_APP_API_URL}/Claims/GetCollectors`,'GET')


    useEffect(() => {
        if(collectors){
            let newCollectorOptions = collectors.map(collector => { 
                return { value: collector.key, label: collector.value } 
            })
            setCollectorOptions(newCollectorOptions);
            setSelectedCollector(newCollectorOptions.find(obj=>obj.label===collector))
        }
    }, [collector,collectors]);

    const assignCollector = (newCollector) => { 
        setLoading(true);
        setStatus(null);
        setError(false);
        FuncFetch(`${process.env.REACT_APP_API_URL}/Claims/PostAssignClaim`,{debtorID,collector:newCollector}, 'POST',null,instance,accounts)
        .then((data)=>{
            setSelectedCollector(collectorOptions.find(obj=>obj.value===newCollector))
            setLoading(false)
            setStatus(200)
            if(updateNCDAndActivities){
                updateNCDAndActivities(data)
            }
        })
        .catch((error)=>{
            setLoading(false)
            setError(true)
            console.error(error)
        })
     }
  return (
    <ErrorBoundary>
        {loadingCollectors ?
            <Spinner animation="border" role="status" />
        : collectors ?
            <ReactSelect
                className={className}
                value={selectedCollector}
                onChange={(e)=>assignCollector(e.value)}
                options={collectorOptions}
                isDisabled={readOnly}
            />
        :null}

        {loading?
            <Spinner animation='border' size='sm'/>
        :error?
            <BsX />
        :(status===200)?
            <BsCheck />
        :null}
    </ErrorBoundary>
  )
}

export default CollectorDropdown