import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import ReactSelect from 'react-select'
import FuncFetch from '../../Utilities/funcFetch'
import Contact from '../../AccountDetailsPopup/AccordionItems/Contact/Contact'
import { useMsal } from '@azure/msal-react'
import './CollectorClaimModal.css'
import TakeClaimAssignment from './TakeClaimAssignment';
import CloseOrOpenAccountButton from './CloseOrOpenAccountButton'
import useFetch from '../../Utilities/useFetch'
import ErrorBoundary from '../../Auth/ErrorBoundary'
import CollectorClaimView from '../CollectorClaimView'

function CollectorClaimModal({
  selectedClaim,
  setSelectedClaim,
  claims,
  show,
  setShow
}) {

  const { instance, accounts } = useMsal();
  const { REACT_APP_API_URL } = process.env;
  const [claimOptions, setClaimOptions] = useState();
  const [phones, setPhones] = useState();
  const [emails, setEmails] = useState();
  const [activities, setActivities] = useState();
  const [accountIsOpen, setAccountIsOpen] = useState();
  const {data: isCollectorAdmin} = useFetch(true,`${REACT_APP_API_URL}/Claims/IsCollectorAdmin`,'GET')
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({
    "account":{}
  });

  useEffect(()=>{
    if(selectedClaim){
        setAccount({
          "account":{}
        })
        setActivities(null)
        setPhones(null)
        setEmails(null)
        setLoading(true);
        FuncFetch(`${REACT_APP_API_URL}/Claims/GetAccountDetail`,{debtorid:selectedClaim},'GET',null,instance,accounts)
        .then(response=>{
            if(response){
              setAccountIsOpen((response.account.accountStatus.toUpperCase())==='OPEN'?true:false)
              // This is a workaround to add an empty object to the payment history array if it is empty
              // If there are values in the array, it adds an empty object to the beginning of the array
              // so there is an empty row for the user to fill out
              if(response.paymentHistory[0]===null){
                response.paymentHistory[0]={}
              }else{
                response.paymentHistory.unshift({})
              }
              setAccount(response)
              setActivities(response.activities);
              setPhones(response.phones)
              setEmails(response.emailAddresses)
              setLoading(false);
            }
        })
    }
  // eslint-disable-next-line
  },[selectedClaim])

  useEffect(()=>{
    // The API returns [null] if there are no results
    if(claims&&claims[0]!==null){
      setClaimOptions(claims.map(claim=>{
        return{value:claim.debtorId,label:'Row: '+claim.rowNumber+' Claim: '+claim.debtorId}
      }))
    }
  },[claims])

  const handleHide = () => { 
    setSelectedClaim(null)
    setShow(false)
   }

  const goToNextClaim = () => { 
    let currentClaimIndex = claimOptions.findIndex(claim=>claim.value===selectedClaim)
    if(currentClaimIndex!==(claimOptions.length-1)){
      setSelectedClaim(null)
      setSelectedClaim(claimOptions[currentClaimIndex+1].value)
    }
   }

   const goToPrevClaim = () => { 
     let currentClaimIndex = claimOptions.findIndex(claim=>claim.value===selectedClaim)
     if(currentClaimIndex!==0){
      setSelectedClaim(null)
      setSelectedClaim(claimOptions[currentClaimIndex-1].value)
    }

   }

  const updateNCDAndActivities = (FieldsAndActivities) => { 
    let { nextContactDate, activities, adverseCarriers } = FieldsAndActivities
    let fieldsWithoutActivities = {...FieldsAndActivities}
    delete fieldsWithoutActivities.activities
    // I have to use this parsing method to turn the date from UTC JSON to readable in the UI
    nextContactDate = new Date(Date.parse(nextContactDate)).toJSON()
    let newAccountInfo = {...account}
    newAccountInfo.account = {...account.account, ...fieldsWithoutActivities}
    newAccountInfo.account.NCD = nextContactDate
    newAccountInfo.account.nextContactDate = nextContactDate
    if(adverseCarriers){
      newAccountInfo.adverseCarriers = adverseCarriers
    }
    setAccount(newAccountInfo)
    if(activities){
      setActivities(activities)
    }
   }

  return (
    <Modal show={show} onHide={handleHide} fullscreen={true} >
        <Modal.Header closeButton>
            <h3 className='margin-right'>Claim {selectedClaim} {account?.client?.clientName} {account?.account?.clientRefNo}</h3>
            {accountIsOpen?
              <>
                <h3 className='margin-right'>| Account Open {account.account?.accountStatusDate?.split('T')[0]}</h3>
                <CloseOrOpenAccountButton accountIsOpen={accountIsOpen} setAccountIsOpen={setAccountIsOpen} account={account.account} updateNCDAndActivities={updateNCDAndActivities} />
              </>
            :accountIsOpen===false?
              <>
                <h3>| Account Closed {account.account?.accountStatusDate?.split('T')[0]}</h3>
                <CloseOrOpenAccountButton accountIsOpen={accountIsOpen} setAccountIsOpen={setAccountIsOpen} account={account.account} updateNCDAndActivities={updateNCDAndActivities} />
              </>
            :null}
            <div className='collector-claim-modal-assign-button'>
              {(account.account?.debtorID&&account.account.collector?.toUpperCase()==='TBD'&&!isCollectorAdmin)?
                <TakeClaimAssignment debtorId={account.account.debtorID} updateNCDAndActivities={updateNCDAndActivities} />
              :null}
            </div>
        </Modal.Header>
        <Modal.Body>
          <ErrorBoundary>
            {selectedClaim?
              <>
              <div className='search-results-wrapper margin-bottom'>
                {(claims&&claimOptions)?
                  <div className='search-results-dropdown'>
                    <Button onClick={()=>goToPrevClaim()}>Previous</Button>
                    <ReactSelect 
                      className='full-width'
                      options={claimOptions} 
                      value={claimOptions.find(claim=>claim.value===selectedClaim)} 
                      onChange={(e)=>setSelectedClaim(e.value)} 
                      />
                      <Button onClick={()=>goToNextClaim()}>Next</Button>
                  </div>
                :
                  <div></div>
                }
                {(phones&&emails)?
                  <>
                    <Contact 
                      phones={phones} 
                      setPhones={setPhones} 
                      emails={emails} 
                      setEmails={setEmails} 
                      activities={activities} 
                      debtorID={account.account?.debtorID} 
                      setActivities={setActivities}
                      updateNCDAndActivities={updateNCDAndActivities}
                    />
                  </>
                :
                  <Spinner animation='border' />
                }
              </div>
              <CollectorClaimView
                claimNumber={selectedClaim} 
                activities={activities}
                setActivities={setActivities}
                loading={loading}
                account={account}
                setAccount={setAccount}
                updateNCDAndActivities={updateNCDAndActivities}
              />
              </>
            :null}
          </ErrorBoundary>
        </Modal.Body>
    </Modal>
  )
}

export default CollectorClaimModal